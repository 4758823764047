import React, { useContext, useState, useEffect } from 'react'
import { Auth, API } from 'aws-amplify'
import NavBar from '../Components/NavBar'
import Context from '../Context/Context'
import { useNavigate } from 'react-router-dom'
//import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Country from '../Components/Auth/Country'
import InstitutionContext from '../Context/InstitutionContext'
import { toast } from 'react-toastify'

const SignUp = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData
  const [firstName, setFirstName] = useState('') // Added for first name
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('91')
  const [country, setCountry] = useState('India')
  //  const [password, setPassword] = useState("");
  //  const [confirmPassword, setConfirmPassword] = useState("");
  const [newUser, setNewUser] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState(0)
  const [err, setErr] = useState('')
  //  const [passwordVisible, setPasswordVisible] = useState(false);
  //  const [isNewUser, setIsNewUser] = useState(true)
  const [signinResponse, setSigninResponse] = useState(null)
  // const data = {
  //   Otp_Msg: `An OTP has been sent to ${email}. Please check your inbox, and in case you don’t find it there, kindly review the spam folder.`
  // }
  const data = {
    Otp_Msg: `An OTP has been sent to +${countryCode}${phoneNumber}. Please check your messages for the OTP.`,
  };
  const UtilCtx = useContext(Context).util
  const UserCtx = useContext(Context)
  const Navigate = useNavigate()

  const [counter, setCounter] = useState(60) // Timer counter
  const [resendVisible, setResendVisible] = useState(false) // Resend OTP visibility

  //  useEffect(() => {
  //    const query = new URLSearchParams(window.location.search)
  //
  //    if (query.get('newuser') === 'false') {
  //      setIsNewUser(false)
  //    }
  //  }, [])

  // Function to handle resend OTP
//  const resendOTP = async (event) => {
//    event.preventDefault()
//    try {
//      if (phoneNumber) {
//        await Auth.resendSignUp(`+${countryCode}${phoneNumber}`)
//        // if (email) {
//        //   await Auth.resendSignUp(email);
//        setCounter(60) // Reset the timer
//        setResendVisible(false) // Hide the resend button
//        setErr('OTP resent successfully.') // Provide appropriate feedback to the user
//      } else {
//        setErr('Please enter your Phone Number.') // Provide appropriate feedback to the user
//        // setErr("Please enter your Email Id."); // Provide appropriate feedback to the user
//      }
//    } catch (error) {
//      setErr(error.message)
//    }
//  }

  useEffect(() => {
    let timer = null
    if (counter > 0) {
      timer = setInterval(
        () => setCounter((prevCounter) => prevCounter - 1),
        1000
      )
    } else {
      setResendVisible(true)
    }

    return () => {
      clearInterval(timer)
    }
  }, [counter])

  //  const passwordVisibilityChange = () => {
  //    setPasswordVisible((prevState) => !prevState);
  //  };

  const form1Validator = () => {
    if (firstName.length === 0) {
      setErr('Enter the Name')
      return false
    } else if (phoneNumber.length < 10) {
      setErr('Enter a Valid Phone Number')
      return false
    } else if (countryCode.length === 0) {
      setErr('Choose Country')
      return false
    } else if (!(email.includes('@') && email.includes('.'))) {
      setErr('Enter a Valid Email')
      return false
    } else if (country.length === 0) {
      setErr('Enter a Country Name')
      return false
      //    } else if (password.length < 8) {
      //      setErr("Password is too Short");
      //      return false;
      //    } else if (password !== confirmPassword) {
      //      setErr("Password Doesn't Match");
      //      return false;
    } else {
      setErr('')
      return true
    }
  }

  const form2Validator = () => {
    if (confirmationCode.length === 0) {
      setErr('Enter a Valid Code')
      return false
    } else {
      setErr('')
      return true
    }
  }

  //  const userExistPhoneNumberSignUp = async () => {
  //    try {
  //      await Auth.signIn(`+${countryCode}${phoneNumber}`)
  //      await API.post('user', `/user/profile/${InstitutionData.InstitutionId}`, {
  //        body: {
  //          emailId: email,
  //          userName: `${firstName} ${lastName}`,
  //          phoneNumber: `+${countryCode}${phoneNumber}`,
  //          country: country
  //        }
  //      })
  //      const userdata = await API.get(
  //        'user',
  //        `/user/profile/${InstitutionData.InstitutionId}`
  //      )
  //      //Temporary
  //      // userdata.Status = true;
  //      UserCtx.setUserData(userdata)
  //      UserCtx.setIsAuth(true)
  //      UtilCtx.setLoader(false)
  //      alert('Signed Up')
  //      if (userdata.status === 'Active') {
  //        UtilCtx.setLoader(false)
  //        Navigate('/dashboard')
  //      }
  //      UtilCtx.setLoader(false)
  //      Navigate('/subscription')
  //    } catch (error) {
  //      UtilCtx.setLoader(false)
  //      if (error.message === 'Incorrect username or password.') {
  //        await userExistEmailIdSignUp()
  //      }
  //      throw error
  //    } finally {
  //      UtilCtx.setLoader(false)
  //    }
  //  }
  //
  //  const userExistEmailIdSignUp = async () => {
  //    try {
  //      //      await Auth.signIn(email, password);
  //      await API.post('user', `/user/profile/${InstitutionData.InstitutionId}`, {
  //        body: {
  //          emailId: email,
  //          userName: `${firstName} ${lastName}`,
  //          phoneNumber: phoneNumber,
  //          country: country
  //        }
  //      })
  //      const userdata = await API.get(
  //        'user',
  //        `/user/profile/${InstitutionData.InstitutionId}`
  //      )
  //      //Temporary
  //      // userdata.Status = true;
  //      UserCtx.setUserData(userdata)
  //      UserCtx.setIsAuth(true)
  //      UtilCtx.setLoader(false)
  //      alert('Signed Up')
  //      if (userdata.status === 'Active') {
  //        UtilCtx.setLoader(false)
  //        Navigate('/dashboard')
  //      }
  //      UtilCtx.setLoader(false)
  //      Navigate('/subscription')
  //    } catch (error) {
  //      UtilCtx.setLoader(false)
  //      throw error
  //    } finally {
  //      UtilCtx.setLoader(false)
  //    }
  //  }

  // const verifyUser = async () => {
  //   try {
  //     await Auth.resendSignUp(email)

  //     setNewUser(true)
  //   } catch (error) {
  //     UtilCtx.setLoader(false)
  //     throw error
  //   } finally {
  //     UtilCtx.setLoader(false)
  //   }
  // }

  //  const sendOTP = async () => {
  //    UtilCtx.setLoader(true)
  //    try {
  //      const response = await Auth.signIn(`+${countryCode}${phoneNumber}`)
  //      setSigninResponse(response)
  //      setNewUser(true)
  //      console.log(response)
  //    } catch (e) {
  //      console.log(e)
  //      setErr(e.message)
  //    } finally {
  //      UtilCtx.setLoader(false)
  //    }
  //  }

  //  function generatePassword(length) {
  //    var charset =
  //      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-='
  //    var password = ''
  //    for (var i = 0, n = charset.length; i < length; ++i) {
  //      password += charset.charAt(Math.floor(Math.random() * n))
  //    }
  //    return password
  //  }

  const onSubmit = async (event) => {
    event.preventDefault()

    UtilCtx.setLoader(true)

    try {
      if (form1Validator()) {
        //        if (!isNewUser) {
        //          await userExistPhoneNumberSignUp()
        //          UtilCtx.setLoader(false)
        //          return
        //        }
        // TRY TO SIGNUP
        try {
          await Auth.signUp({
            username: `+${countryCode}${phoneNumber}`,
            // username: email,
            password: 'Password@123',
            attributes: {
              phone_number: `+${countryCode}${phoneNumber}`,
              name: `${firstName} ${lastName}`,
              email: email
            }
          })
        }
        catch (e) {
          console.error(e);
        }
        // EVEN IF THERE IS AN ERROR, TRY TO SIGNIN
        finally {
          const phoneResponse = await API.post(
            'user',
            `/any/phone-exists/${InstitutionData.InstitutionId}`,
            {
              body: {
                phoneNumber: `+${countryCode}${phoneNumber}`
              }
            }
          )

          if (phoneResponse.exists === true) {
            toast.warn('An account with this phone number already exists. Please login or signup with a different number.')
          }
          else {
            const response = await Auth.signIn(`+${countryCode}${phoneNumber}`)
            console.log(response)
            setSigninResponse(response)
            setNewUser(true)
          }
        }
      }
      UtilCtx.setLoader(false)
    } catch (e) {
      //      if (e.message === 'User already exists') {
      //        await sendOTP()
      //        UtilCtx.setLoader(false)
      //        return
      //      }
      console.log(e)
      setErr(e.message)
      UtilCtx.setLoader(false)
    }
  }

  const onConfirmationSubmit = async (event) => {
    event.preventDefault()

    UtilCtx.setLoader(true)

    try {
      if (form2Validator()) {
        //        await Auth.confirmSignUp(
        //          `+${countryCode}${phoneNumber}`,
        //          // email,
        //          confirmationCode
        //        );
        //        await Auth.signIn(`+${countryCode}${phoneNumber}`);
        // await Auth.signIn(email, password);

        await Auth.sendCustomChallengeAnswer(
          signinResponse,
          `${confirmationCode}`
        )
        await Auth.currentSession()
        await API.post(
          'user',
          `/user/profile/${InstitutionData.InstitutionId}`,
          {
            body: {
              emailId: email,
              userName: `${firstName} ${lastName}`,
              phoneNumber: `+${countryCode}${phoneNumber}`,
              country: country
            }
          }
        )
        const userdata = await API.get(
          'user',
          `/user/profile/${InstitutionData.InstitutionId}`
        )

        UserCtx.onAuthLoad(true, InstitutionData.InstitutionId)
        //Temporary
        // userdata.Status = true;
        UserCtx.setUserData(userdata)
        UserCtx.setIsAuth(true)
        UtilCtx.setLoader(false)
        toast.info('Signed Up')
        if (userdata.status === 'Active') {
          Navigate('/dashboard')
        }
        Navigate('/subscription')
      } else {
        UtilCtx.setLoader(false)
      }
      UtilCtx.setLoader(false)
    } catch (e) {

      console.log(e)
      if (e === 'No current user') {
        setErr('Incorrect OTP. Try again.')
      }
      else if (e.message === 'Invalid session for the user.' || e.message === 'Incorrect username or password.') {
        toast.warn('OTP expired. Please signup again.')
        setNewUser(false)
      }
      else {
        setErr(e.message)
      }
      UtilCtx.setLoader(false)
    }
  }

  const form1 = () => {
    return (
      <form
        className={`w-[50vw] max800:w-[90vw] max-w-[29rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center `}
      >
        <h3 className={`text-[1.1rem] font-[700]`}>Sign Up</h3>
        <div className={`text-center`}>
          <p className={`w-[13.5rem] font-[500] my-2 text-[0.8rem]`}>
            Unlock Your Potential, Sign Up Today for a Healthier Tomorrow!
          </p>
        </div>
        <ul className={`flex flex-col items-center px-0`}>
          <li className={`flex items-center gap-1 mt-8 max500:flex-col max500:gap-2 max500:items-start`}>
            <input
              className={`w-[9.5rem] border-[2px] px-3 py-2  rounded-2 max500:w-[80vw] max500:mb-4`}
              value={firstName}
              placeholder="First Name"
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 15) {
                  setFirstName(inputValue);
                }
              }}
            />
            <input
              className={`w-[9.5rem] border-[2px] px-3 py-2 rounded-2 max500:w-[80vw]`}
              value={lastName}
              placeholder="Last Name"
              onChange={(e) => {
                const inputValue = e.target.value;
                const regex = /^[A-Za-z]*$/; // Regex to allow only alphabets
                if (inputValue.length <= 15 && regex.test(inputValue)) {
                  setLastName(inputValue);
                }
              }}
            />
          </li>
          <li className={`flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative`}>
            <div className={`relative`}>
              <input
                className={`w-[19.5rem] border-[2px] px-6 py-2 rounded-2 max500:w-[80vw]`}
                type="text"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </li>
          <li className={`flex gap-20 mt-2 ml-6 max500:flex-col max500:gap-2 max500:items-start relative`}>
            <select
              value={countryCode}
              name="countryCode"
              id=""
              className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 rounded-2 max500:w-[80vw] mt-6`}
              onChange={(e) => {
                const index = e.target.options.selectedIndex;
                const text = e.target.options[index].text;
                setCountry(text.split(' ')[0]);
                setCountryCode(e.target.value.toString());
              }}
            >
              {<Country />}
            </select>
          </li>
          <li className={`flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative`}>
            <div className={`relative`}>
              <input
                className={`w-[19.5rem] border-[2px] px-6 py-2 rounded-2 max500:w-[80vw]`}
                type="text"
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
                    setPhoneNumber(inputValue);
                  }
                }}
              />
            </div>
          </li>
        </ul>
        {err && <p className={`text-[0.8rem]  mt-2 text-red-500`}>{err}</p>}
        <button
          className={`w-[19.5rem] py-2 mt-3 mb-3 text-white rounded-2 max500:w-[80vw]`}
          style={{
            backgroundColor: InstitutionData.LightPrimaryColor,
          }}
          onClick={onSubmit}
        >
          Sign Up
        </button>
        <p className={`text-[0.8rem] text-black text-center w-[19.5rem]`}>
          *By creating an account you agree to our{' '}
          <span
            className={`cursor-pointer`}
            onClick={() => {
              Navigate('/terms');
            }}
            style={{
              color: InstitutionData.LightPrimaryColor,
            }}
          >
            Terms of Use
          </span>{' '}
          as well as{' '}
          <span
            className={`cursor-pointer`}
            onClick={() => {
              Navigate('/privacypolicy');
            }}
            style={{
              color: InstitutionData.LightPrimaryColor,
            }}
          >
            Privacy Policy
          </span>
        </p>
        <p className={`text-[0.85rem] text-black cursor-pointer`} onClick={() => { Navigate('/login'); }}>
          Already logged In ?{' '}
          <span className={`font-[500]`} style={{ color: InstitutionData.LightPrimaryColor }}>
            Sign In
          </span>
        </p>
      </form>
    );
  }

  const form2 = () => {
    return (
      <form
        className={`w-[50vw] max800:w-[90vw] max-w-[35rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center `}
      >
        <h3 className={`text-[1.2rem] font-roboto font-bold`}>Sign Up</h3>
        <ul className={`flex flex-col items-center`}>
          <li
            className={`flex items-center gap-20 mt-8 max500:flex-col max500:gap-2 max500:items-start`}
          >
            <label className={`w-20 max500:ml-3`}>OTP Code</label>
            <ValidatorForm>
              <TextValidator
                label={
                  <span style={{ color: InstitutionData.LightPrimaryColor }}>
                    Enter 6 Digit OTP
                  </span>
                }
                variant="outlined"
                inputProps={{ maxLength: 6 }}
                name="otp"
                size="small"
                type="text"
                fullWidth
                validators={['required']}
                errorMessages={['OTP is required']}
                value={confirmationCode === 0 ? '' : confirmationCode}
                onChange={(e) => {
                  setConfirmationCode(e.target.value)
                }}
              />
            </ValidatorForm>
          </li>
          {resendVisible ? (
            <button className={`mt-[1rem] ml-[5rem]`} onClick={onSubmit}>
              Resend OTP
            </button>
          ) : (
            <p className={`mt-[1rem]`}>
              Resend OTP in{' '}
              <span
                className={`font-bold`}
                style={{
                  color: InstitutionData.LightPrimaryColor
                }}
              >
                {counter}
              </span>{' '}
              seconds
            </p>
          )}
        </ul>
        {err && <p className={`text-[0.8rem] mt-2 text-red-500`}>{err}</p>}
        <p className={`text-center w-[80%] text-[0.81rem]`}>
          <strong className={`text-red-500`}>Note*</strong>
          {data.Otp_Msg}
        </p>
        <button
          className={`p-4 py-1 mt-6 mb-3 text-white rounded-lg`}
          style={{
            backgroundColor: InstitutionData.LightPrimaryColor
          }}
          onClick={onConfirmationSubmit}
        >
          Confirm code
        </button>
      </form>
    )
  }

  return (
    <div className={`w-screen min-h-screen`}>
      <NavBar />
      <div className={`flex flex-col items-center text-black`}>
        {/* <h3 className={`text-[2rem]`}>FITNESS</h3>
        <div className={`w-[80%] h-[0.08rem] bg-black flex`}></div> */}
        <div className={`flex w-[100%] gap-16 justify-center items-end mt-[8rem]`}>
          {!newUser ? form1() : form2()}
        </div>
      </div>
    </div>
  )
}

export default SignUp
