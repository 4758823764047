import React, { useContext } from 'react';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Home/Footer';
import './Refund.css';
import InstitutionContext from '../Context/InstitutionContext';

const Refund = () => {
  const institutionData = useContext(InstitutionContext)?.institutionData;

  // Function to convert URLs to hyperlinks
  const convertLinks = (text) => {
    const urlRegex = /(www\.rtiger\.happyprancer\.com)/g;
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href="https://rtigers.happyprancer.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <>
      <div className="flex flex-col items-center w-full pb-[5rem]">
        <NavBar />
        <div className="text-justify flex flex-col items-center w-[90vw] max-w-[80rem] p-0 overflow-x-hidden">
          <h1 className="nor sans-serif text-[4rem] mt-[7rem] text-center">
            Privacy Policy
          </h1>
          <h1 className="text-[1.2rem] max450:text-[1rem] text-center font-bold w-full">
            {institutionData?.src_pages_Refund__Heading_2}
          </h1>
          <p className="text-justify sm:ml-0 ml-5 mr-5">
            {institutionData?.src_pages_Refund__Content_2}
          </p>
          {institutionData?.Refund.map((refund, index) => (
            <div key={index} className="w-full sm:ml-0 ml-5">
              <h4 className="text-[1.2rem] max450:text-[1rem] text-left mt-8 font-bold w-full">
                {refund.heading}
              </h4>
              <div className="text-justify">
                {refund.content.split('\n\n').map((para, paraIndex) => (
                  <p key={paraIndex} className="mt-2">
                    {convertLinks(para)}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Refund;
