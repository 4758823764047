import React, { useContext } from 'react';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Home/Footer';
import InstitutionContext from '../Context/InstitutionContext';

const PrivacyPolicy = () => {
  const institutionData = useContext(InstitutionContext)?.institutionData;

  // Function to convert URLs to hyperlinks
  const convertLinks = (text) => {
    const urlRegex = /(www\.rtiger\.happyprancer\.com)/g;
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href="https://rtigers.happyprancer.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="flex flex-col items-center">
      <NavBar />
      <div className="w-[82vw] mt-[7rem]">
        <h1 className="nor sans-serif text-[4rem] text-center">
          Privacy Policy
        </h1>
        {institutionData?.PrivacyPolicy.map((item, index) => (
          <div key={index} className="w-full sm:ml-0 ml-5">
            {index === 0 ? (
              <h1 className="text-center text-[4rem] font-bebas-neue">
                {item.heading}
              </h1>
            ) : (
              <h4 className="text-[1.2rem] max450:text-[1rem] text-left mt-8 font-bold w-full">
                {item.heading}
              </h4>
            )}
            {item.content && (
              <div className="text-justify mt-8 sm:ml-0 ml-5 mr-5">
                {item.heading === 'Contact Us'
                  ? item.content.split('\n').map((line, i) => (
                      <p key={i} className="mt-2">
                        {convertLinks(line)}
                      </p>
                    ))
                  : <p>{convertLinks(item.content)}</p>}
              </div>
            )}
          </div>
        ))}
      </div>
      <Footer className="mt-4" />
    </div>
  );
};

export default PrivacyPolicy;
