import { API } from 'aws-amplify'

// Function to get the current date in the format "YYYY-MM-DD"
const getCurrentDate = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const fetchStreakCount = async (institutionid) => {
  try {
    const response = await API.get('user', `/user/streak-get/${institutionid}`)

    // Check if the necessary properties exist in the response
    if (
      response &&
      response.streak !== undefined &&
      response.level !== undefined
    ) {
      // Ensure to return the correct object with 'level' property
      return { streakCount: response.streak, level: response.level }
    }

    // If 'streak' or 'level' is undefined, you may want to handle this case accordingly
    return { streakCount: 0, level: 0 } // Default values or handle as needed
  } catch (error) {
    console.error('Error in fetchStreakCount:', error)
    throw error
  }
}

const onJoinClass = async (institutionid) => {
  try {
    // Check if the function was already called today
    const lastCallDate = localStorage.getItem('lastOnJoinClassCall')
    const currentDate = getCurrentDate()

    if (lastCallDate === currentDate) {
      return
    }

    const response = await API.put(
      'user',
      `/user/streak-update/${institutionid}`
    )

    // Check if the response has a 'json' method before calling it
    if (response.json && typeof response.json === 'function') {
      const data = await response.json()
      console.log('On Join Class Response:', data)
    } else {
      console.warn('Response does not have a json() method:', response)
      // Handle the response without json() method, if needed
    }

    // Store the current date in local storage
    localStorage.setItem('lastOnJoinClassCall', currentDate)
  } catch (error) {
    console.error('Error in onJoinClass:', error)
    throw error
  }
}

export { fetchStreakCount, onJoinClass }
