import React, { useContext } from 'react';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Home/Footer';
import InstitutionContext from '../Context/InstitutionContext';

const Terms = () => {
  const institutionData = useContext(InstitutionContext)?.institutionData;

  // Function to convert URLs to hyperlinks
  const convertLinks = (text) => {
    const urlRegex = /(www\.rtiger\.happyprancer\.com)/g;
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href="https://rtigers.happyprancer.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <>
      <div className="flex flex-col items-center w-full pb-[5rem]">
        <NavBar />
        <div className="text-justify flex flex-col items-center w-[90vw] max-w-[80rem] p-0 overflow-x-hidden">
          {institutionData?.TermsData.map((item, index) => (
            <div key={index} className="w-full sm:ml-0 ml-5">
              {index === 0 ? (
                <h1 className="text-center text-[4rem] font-bebas-neue mt-[6rem]">
                  {item.title}
                </h1>
              ) : (
                <h4 className="text-[1.2rem] max450:text-[1rem] text-left mt-8 font-bold w-full">
                  {item.title}
                </h4>
              )}
              {item.content && (
                <div className="text-justify mt-8 sm:ml-0 ml-5 mr-5">
                  {item.title === 'Modification'
                    ? item.content.split('\n').map((line, i) => (
                        <p key={i} className="mt-2">
                          {convertLinks(line)}
                        </p>
                      ))
                    : <p>{convertLinks(item.content)}</p>}
                </div>
              )}
              {item.additionalContent1 && (
                <p className="text-justify mt-2">
                  {convertLinks(item.additionalContent1)}
                </p>
              )}
              {item.additionalContent2 && (
                <p className="text-justify mt-2">
                  {convertLinks(item.additionalContent2)}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
