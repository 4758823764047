import React, { useContext } from 'react'
import InstitutionContext from '../../Context/InstitutionContext'

const Header = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData

  return (
    <div className={` flex items-center justify-center h-[30rem] pb-20 mt-[5rem]`}>
      <div
        className={`absolute z-10 flex flex-col items-center w-screen content `}
      >
        <div className={`w-[auto] text-left flex flex-column`}>
          <p className={`italic text-[2rem] max800:text-[1.2rem] max536:ml-6 max1250:text-[1.8rem] text-white`} >{InstitutionData.TagLine1 || ""}</p>
          <h1
            className={`w-full italic max1250:w-[100%] max536:w-[80vw]  max800:w-[100%]  text-[2.5rem] max536:text-[1.4rem] max536:ml-4 max800:text-[1.8rem] max536:text-left max1250:text-[2.5rem] text-white `}
          >
            {InstitutionData.TagLine}
          </h1>
        </div>
      </div>
      <div className={`-z-10 `}>
        <video
          autoPlay
          loop
          muted
          playsInline={true}
          controls={false}
          className={`object-cover object-top h-[38rem] w-screen max-w-screen max600:h-[35rem]`}
        >
          <source
            src={`${InstitutionData.videoUrl}`}
            type="video/mp4"
          />
        </video>
        {/* <img
        src={InstitutionData.videoUrl}
        alt="bg-img"
        className={`object-cover object-top h-[38rem] w-screen bg-[#000000]  max-w-screen `}
        /> */}
      </div>
    </div>
  )
}

export default Header