import React, { useContext, useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify'
import NavBar from '../Components/NavBar'
import Context from '../Context/Context'
import { useNavigate } from 'react-router-dom'
import './Login.css'
import Country from '../Components/Auth/Country'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import InstitutionContext from '../Context/InstitutionContext'
import { toast } from 'react-toastify'

const NewLogin = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('91')
  const [err, setErr] = useState('')
  const UtilCtx = useContext(Context).util
  const UserCtx = useContext(Context)
  const [OTP, setOTP] = useState('')
  const [signinResponse, setSigninResponse] = useState(null)
  const [otpTimer, setOtpTimer] = useState(null)
  const [showSendOTPButton, setShowSendOTPButton] = useState(false)
  const [otpSent, setOtpSent] = useState(false)

  const Navigate = useNavigate()

  useEffect(() => {
    let timerInterval = null;

    if (otpTimer === 0) {
      clearInterval(timerInterval); // Stop the timer when it reaches 0
    } else if (otpTimer > 0) {
      timerInterval = setInterval(() => {
        setOtpTimer(prevTimer => prevTimer - 1); // Decrement the timer every second
      }, 1000);
    }
    return () => clearInterval(timerInterval); // Cleanup function to clear interval on component unmount
  }, [otpTimer]);

  const startTimer = () => {
    setOtpTimer(30); // Set the timer to 30 seconds
  }

  const sendOTP = async (event) => {
    event.preventDefault()
    UtilCtx.setLoader(true)

    try {
      const exist = await API.post(
        'user',
        `/any/phone-exists/${InstitutionData.InstitutionId}`,
        {
          body: {
            phoneNumber: `+${countryCode}${phoneNumber}`
          }
        }
      )
      if (exist.exists) {
        const response = await Auth.signIn(`+${countryCode}${phoneNumber}`)
        setSigninResponse(response)
        startTimer() // Start the timer when OTP is sent
        setOtpSent(true)
      } else {
        toast.error('Sign Up First')
        Navigate('/signup')
      }
    } catch (e) {
      if (e.message === 'Unexpected Lambda Output') {
        toast.error('Sign Up First')
        Navigate('/signup')
      }
      setErr(e.message)
    } finally {
      UtilCtx.setLoader(false)
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    UtilCtx.setLoader(true)

    try {
      const user = await Auth.sendCustomChallengeAnswer(signinResponse, OTP)
      console.log(await Auth.currentSession())
      if (user) {
        const userdata = await API.get(
          'user',
          `/user/profile/${InstitutionData.InstitutionId}`
        )
        UserCtx.setUserData(userdata)
        UserCtx.setIsAuth(true)
        UtilCtx.setLoader(false)
        toast.info('Logged In')
        UserCtx.onAuthLoad(true, InstitutionData.InstitutionId)
        Navigate('/dashboard')
      } else {
        setErr(`Incorrect +${countryCode}${phoneNumber}`)
        UtilCtx.setLoader(false)
      }
    } catch (e) {
      setErr(e.message)
    } finally {
      UtilCtx.setLoader(false)
    }
  }
  //resend otp button
  useEffect(() => {
    if (otpTimer === 0) {
      setShowSendOTPButton(true);
    }
  }, [otpTimer]);

  return (
    <div className={`w-screen min-h-screen`}>
      <NavBar />
      <div className={`flex flex-col items-center text-black`}>
        <div className={`flex w-[100%] gap-16 justify-center items-end mt-[10rem]`}>
          <form
            className={`w-[50vw] max800:w-[90vw]  max-w-[28rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center `}
          >
            <h3 className={`text-[1.2rem] font-[700]`}>Login</h3>
            <p className={`text-center font-[500] text-[0.8rem] mb-[0rem]`}>
              Hey, Enter your details to sign in <br /> to your account
            </p>
            <ul className={`flex flex-col items-center`}>
              <select
                name="countryCode"
                id=""
                value={countryCode}
                className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 rounded-2 max500:w-[80vw] mt-6`}
                onChange={(e) => {
                  setCountryCode(e.target.value.toString())
                }}
              >
                {<Country />}
              </select>
              <li
                className={`flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative`}
              >
                <div className={`relative`}>
                  <input
                    className={`w-[19.5rem] mr-[1.5rem] border-[2px] px-6 py-2 rounded-2 max500:w-[80vw]`}
                    type="text"
                    placeholder="Enter Phone"
                    value={phoneNumber}
                    onChange={(e) => {
                      const inputValue = e.target.value
                      if (/^\d*$/.test(inputValue) || inputValue === "") {
                        if (inputValue.length <= 10 || inputValue === "") {
                          setPhoneNumber(inputValue)
                        }
                      }
                    }}
                  />
                </div>
              </li>
              <li
                className={`flex gap-20 mr-[20px] mt-8  max500:flex-col max500:gap-2 max500:items-start relative`}
              >
                <div className={`relative`}>
                  <ValidatorForm>
                    <TextValidator
                      label={
                        <span>
                          Enter 6 Digit OTP
                        </span>
                      }
                      className={`w-[19.5rem] mr-[1.5rem] border-[2px] rounded-2 max500:w-[80vw]`}
                      variant="outlined"
                      inputProps={{ maxLength: 6 }}
                      size="small"
                      type="text"
                      validators={['required']}
                      value={OTP}
                      onChange={(e) => setOTP(e.target.value)}
                      disabled={!signinResponse}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          onSubmit(e);
                        }
                      }}
                    />
                  </ValidatorForm>
                </div>
              </li>
            </ul>
            {err && <p className={`text-[0.8rem] mt-2 text-red-500`}>{err}</p>}
            {!signinResponse && (
              <>
                {!showSendOTPButton ? (
                  <button
                    className={`w-[19.5rem] py-2 my-3 ml-2 rounded-2 max500:w-[80vw]`}
                    style={{
                      backgroundColor: phoneNumber ? InstitutionData.LightPrimaryColor : InstitutionData.LightPrimaryColor,
                      color: '#ffffff',
                      opacity: phoneNumber ? 1 : 0.5
                    }}
                    onClick={sendOTP}
                    disabled={phoneNumber.length !== 10 || (otpTimer !== null && otpTimer > 0)}
                  >
                    {otpTimer !== null && otpTimer > 0 ? `Resend OTP in ${otpTimer} seconds` : 'Send OTP'}
                  </button>
                ) : (
                  <button
                    className={`w-[19.5rem] py-2 mb-3  rounded-2 max500:w-[80vw]`}
                    style={{
                      backgroundColor: phoneNumber ? InstitutionData.LightPrimaryColor : InstitutionData.LightPrimaryColor,
                      color: '#ffffff',
                      opacity: phoneNumber ? 1 : 0.5
                    }}
                    onClick={sendOTP}
                    disabled={phoneNumber.length !== 10}
                  >
                    Send OTP
                  </button>
                )}
              </>
            )}

            {signinResponse && (
              <>
                <button
                  className={`w-[19.5rem] py-2 mt-3 mb-3 text-white rounded-2 max500:w-[80vw]`}
                  style={{
                    backgroundColor: InstitutionData.LightPrimaryColor,
                    opacity: OTP ? 1 : 0.5
                  }}
                  onClick={onSubmit}
                  disabled={!OTP}
                >
                  Sign in
                </button>
                {otpSent && (
                  <p className="text-[0.75rem] text-black mb-2">
                    *An OTP has been sent. Please check your inbox for the OTP.
                  </p>
                )}
                {otpTimer !== null && otpTimer > 0 ? (
                  <p className="text-[0.9rem] text-black">Resend OTP in {otpTimer} seconds</p>
                ) : (
                  <p
                    className="text-[0.9rem] text-black cursor-pointer"
                    onClick={sendOTP}
                  >
                    Resend OTP
                  </p>
                )}
              </>
            )}
            <p
              className={` text-[0.85rem] text-black cursor-pointer`}
              onClick={() => {
                Navigate('/signup')
              }}
            >
              Don’t have an account?{' '}
              <span
                className={`font-[500]`}
                style={{
                  color: InstitutionData.LightPrimaryColor
                }}
              >
                Register Now
              </span>{' '}
            </p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default NewLogin
