import React, { useContext } from 'react'
import Faq from 'react-faq-component'
import './FAQ.css'
import InstitutionContext from '../../Context/InstitutionContext'

export default function FAQ() {
  const institutionData = useContext(InstitutionContext).institutionData

  const data = {
    rows: institutionData.FAQ
  }

  const styles = {
    bgColor: '#ffffff',
    rowTitleColor: '#000',
    rowContentColor: '#555555',
    arrowColor: '#000'
  }

  const config = {
    animate: true,
    //arrowIcon: "V",
    tabFocus: true
  }
  return (
    <div
      className={`home-faq flex flex-col  items-center justify-center gap-[5rem] max800:py-[20rem]`}
    >
      <div className={` flex flex-col p-[5rem] max800:px-[5rem] `}>
        <div
          className={`rounded-6xl box-border w-[18.88rem] h-[3.06rem] shrink-0 overflow-hidden flex flex-row  items-center justify-center`}
        >
          <div
            className={`flex flex-row p-[0.53rem] items-center justify-center`}
          >
            <div className={`relative textfont font-bold`}>FAQs</div>
          </div>
        </div>
      </div>

      <Faq data={data} styles={styles} config={config} />
    </div>
  )
}
